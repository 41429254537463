
.kontainer-review-anime-apl-animemo{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 25px;

    .judul-review-apl{
        font-size: 25px;
        font-weight: 700;
        color: var(--warna-teks);
        margin: 0;
        margin-bottom: 15px;
        text-align: center;
        width: 100%;
    }
    .review-anime{
        display: flex;
        flex-direction: column;
        list-style-type: none;
        width: 90%;
        padding-left: 0;
    }
}