@import '../../../../../css/Akar.scss';

.list-anime-pengguna{
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    width: 100%;
    height: 110px;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 5px;
    background-color: var(--bg);
    transition: 200ms;

    &:hover{
        cursor: pointer;
        filter: brightness(1.2);
        background-color: $bg-terang;
    }

    .gbr-anime{
        height: 100%;
        width: 90px;
        object-fit: cover;

    }

    .kontainer-koleksi{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 4px;
        width: calc(100% - 90px);

        .judul-anime{
            margin: 0;
            font-size: 14px;
            font-weight: 500;
            text-align: left;
        }

        .rating-anime{
            display: flex;
            width: 100%;
            justify-content: space-between;

            .rating{
                display: flex;
                flex-direction: row;
                height: fit-content;
                align-items: center;

                svg{
                    width:20px;
                    height: 20px;

                }

                .nilai{
                    margin: 0;
                    margin-left: 5px;
                    font-size: 14px;
                    font-weight: 500;
                    color: $warna-teks;

                }
            }

            .episode-nonton{
                margin: 0;
                font-size: 12px;
                font-weight: 400;
                
            }

        }
    }
}