

.isi-pemberitahuan-apl-animemo{
    display: flex;
    flex-direction: column;
    $paddingDua: 40px;
    $lebar: calc(100% - #{$paddingDua});
    width: $lebar;
    padding: calc(#{$paddingDua}/2);
    border-radius: 20px;
    &:hover{
        background-color: var(--bg-aksen);
        cursor: pointer;
    }

    .judul{
        font-size: 15px;
        font-weight: 800;
        color: var(--warna-teks);
        margin: 0;
        margin-bottom: 4px;
        width: 100%;
    }

    .waktu{
        font-size: 11px;
        font-weight: 400;
        color: var(--warna-teks-tak-menyala-terang);
        margin: 0;
        margin-bottom: 7px;
        width: 100%
    }

    .isi{
        font-size: 12px;
        font-weight: 600;
        color: var(--warna-teks);
        margin: 0;
        margin-bottom: 10px;
        width: 100%;
    }
}