

.kontainer-anime-musiman-apl-animemo{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000049;
    z-index: 2;

    .tbl-tutup{
        position: fixed;
        top: 8.5%;
        right: 8.5%;
        width: 45px;
        height: 45px;
        border-radius: 22.5px;
        background-color: var(--warna-tema-primary);
        border: none;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover{
            cursor: pointer
        }
        @media screen and (max-width: 400px) {
            width: 30px;
            height: 30px
        }

        svg{
            color: var(--warna-teks);
            width: 45px;
            height: 45px;
            @media screen and (max-width: 400px) {
                width: 30px;
                height: 30px
            }
        }
    }

    .isi-anime-musiman-apl-animemo{
        border-radius: 10px;
        background-color: var(--bg-aksen);
        position: absolute;
        left: 10%;
        top: 10%;
        width: 80%;
        height: 90%;
        overflow: auto;
        &::-webkit-scrollbar{
            display: none;
        }

    }
}

.konten-musiman-laman{
    border-radius: 20px;
    overflow: hidden;

    .tab-tab-musim{
        width: 100%;
        display: flex;
        flex-direction: column;
        
        .pengaturan-urutan{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0 0 10px 0;
            
            .urutan{
                display: flex;
                flex-direction: row;

                .isi{
                    &:last-child{
                        margin-left: 5px;
                    }
                }
            }
        }
    }

}