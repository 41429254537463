@import "../../../css/Akar.scss";

.peringatan-tambahkan-anime-apl{
    font-size: 20px;
    font-weight: 500;
    margin: 0;

}

.tambahkan-anime-apl{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: var(--bg);

    .judul-anime{
        font-size: 30px;
        font-weight: 500;
        margin: 5px 0 5px 0;
    }

    .kontainer-gbr-tbh-anime{
        width: 100%;
        padding-bottom: 100%;
        position: relative;
        margin-bottom: 10px;

        @media screen and (min-width: $pindah-ui-5){
            width: 50%;
            padding-bottom: 50%;
        }

        .gbr{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
    .input-skor-episode{
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
        
        .judul{
            font-size: 18px;
            font-weight: 600;
            margin: 5px 0 5px 0;
            color: $teks-abu-abu;
        }

        .input-angka{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 20px 0 20px;

            .kontainer-input{
                width: 50px;
            }
        }
    }

    .review-pengguna{
        margin: 0px;
        margin-bottom: 30px;
        width: 100%;
    }

    .tanggal-nonton{
        margin: 0 0 30px 0;

        .isi{
            display: flex;
            flex-direction: row;

            svg{
                font-size: 20px;
            }
        }
    }

    .klaster-status-nonton{
        display: flex;
        flex-direction: row;
        margin-bottom: 50px;
        flex-wrap: wrap;
        justify-content: center;
    }

    .kontainer-tbl-upload{
        margin-bottom: 60px;
    }


}