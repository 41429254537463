@import '../../../css/Akar.scss';

.kontainer-teks-input-radio{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-bottom: var(--teks-tak-menyala);
    height: 48px;
    border-radius: 5px;
}

.teks-input-radio{
    margin: 0;
    color: var(--teks-tak-menyala);
    font-size: large;
    font-weight: 500;
    padding-right: 5px;
    padding: 0 5px;
    transition: background-color 200ms;
}

.teks-input-radio-terpilih{
    margin: 0;
    font-size: large;
    font-weight: 500;
    color: var(--warna-teks);
    padding-right: 5px;
    padding: 0 10px;
    transition: background-color 200ms;
}

.teks-input-radio:hover{
    background-color: var(--bg-aksen);
    cursor:pointer
}

.teks-input-radio-terpilih:hover{
    background-color: var(--bg-aksen);
    cursor:pointer
}