@import '../../../../../css/Akar.scss';


.tab-musim-ini-musiman{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 13px;
    list-style-type: none;
    padding-left: none;
    @media screen and (max-width: $pindah-ui-6){
        grid-template-columns: 49% 49% ;
        row-gap: 5px;
        column-gap: 2%;
    }
}