@import '../../../../css/Akar.scss';

.isi-aktivitas-profil{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.item-isi-aktivitas-profil{
    display: flex;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    flex-direction: row;
    width: 400px;
    height: fit-content;
    border-radius: 10px;
    background: var(--bg-terang-terang);
    justify-content: space-between;
    align-items: center;
    &:hover{
        cursor: pointer;
        background: var(--bg-aksen);
    }
    .gbr{
        width: 40px;
        height: 40px;
        object-fit: contain;
        border-radius: 17.5px;
    }

    .kontainer-teks{
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: calc(100% - 50px);
    }
}

.teks-isi-aktivitas-profil{
    margin: 0;
    width: 100%;
    font-size: 15px;
    height: fit-content;

}

.waktu-isi-aktivitas-profil{
    margin-top: 15px;
    width: 100%;
    font-size: 12px;
    height: fit-content;
}

@media screen and (orientation:portrait){
    .item-isi-aktivitas-profil {
        width: 90%;
    }
}