@import '../../../css/Akar.scss';

.ortu-forum-apl-animemo{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;

    .list-ortu-forum{
        width: 100%;
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .fab-tambah-feeds{
        position: fixed;
        right: calc(20px + #{$ukuran-nav-kanan});
        bottom: 20px;
        @media only screen and (max-width: $pindah-ui-5) {
            right: 20px;
            bottom: calc(env(safe-area-inset-bottom) + #{$ukuran-nav-bawah} + 20px)
        }
    }
}