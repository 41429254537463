

.tambahkan-feeds-apl-animemo{
    display: flex;
    flex-direction: column;
    align-items: center;
    .kontainer-tbl{
        display: flex;
        margin-top: 20px;
        width: 120px;
        height: 36.5px;
        margin-bottom: 20px;
        span{
            width: 100%;
        }
    }
}