@import '../../../../css/Akar.scss';

.item-info-cepat-profil{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.gambar-info-cepat-profil {
    display: grid;
    color: var(--teks-abu-abu);
    place-items: center;
}

.gambar-info-cepat-profil svg{
    font-size: large;
}


.isi-info-cepat-profil{
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    color: var(--teks-abu-abu);
    font-size: large;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
}

@media screen and (orientation:portrait){
    .isi-info-cepat-profil {
        font-size: medium;
    }
    .gambar-info-cepat-profil svg{
        font-size: medium;
    }
}