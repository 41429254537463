@import "../../../../../css/Akar.scss";

.review-pengguna-apl-animemo{
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    border-radius: 15px;
    margin-bottom: 10px;
    background-color: var(--bg-aksen);
    padding: 20px;

    h6{
        margin: 0
    }

    .foto-nama{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 15px;

        img{
            width: 50px;
            height: 50px;
            object-fit: contain;
            border-radius: 25px;
            margin-right: 10px;
            &:hover{
                cursor: pointer;
            }
        }

        .nama-waktu{
            display: flex;
            flex-direction: column;
            width: calc(100% - 35px);

            .nama{
                font-size: 18px;
                font-weight: 600;
                color: var(--warna-teks);
                margin-bottom: 3px;
                text-align: left;

            }

            .waktu{
                font-size: 12px;
                font-weight: 400;
                color: var(--teks-tak-menyala);
                text-align: left;
            }
        }

    }

    .review{
        font-size: 13px;
        font-weight: 400;
        color: var(--teks-tak-menyala-terang);
        margin-bottom: 5px;
        text-align: left;
        @media screen and (min-width: $pindah-ui-3) {
            font-size: 16px;
        }
    }

    .skor-pengguna{
        width: 100%;
        text-align: center;
        margin-bottom: 5px;
        font-size: 15px;
        font-weight: 500;
        color: var(--warna-teks);
    }

    .ulasan-membantu{
        width: 100%;
        font-size: 11px;
        font-weight: 400;
        color: var(--teks-tak-menyala);
        @media screen and (min-width: $pindah-ui-4) {
            font-size: 13px;
        }
    }
}