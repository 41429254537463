@import "../../css/Akar.scss";

.konten-beranda-apl-animemo{
    display: flex;
    flex-direction: column;
    align-items: center;
    .kontainer-carousel-berita{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 30px;
        @media screen and (max-width: 800px) {
            margin-top: 0;
        }
        .isi-carousel-berita{
            width: 100%;
            height: 300px;
            overflow: hidden;

            .gbr-carousel{
                width: 50%;
                height: 100%;
                object-fit: cover;
                border-radius: 50px;
                @media screen and (max-width: 800px) {
                    width: 100%;
                    border-radius: 0;
                }
            }

            .kontainer-teks-berita{
                position: absolute;
                bottom: 0px;
                display: flex;
                align-items: flex-end;
                height: 100px;
                width: 100%;
                background-image: linear-gradient(to top, var(--bg-gelap), var(--bg-transparan-full));
                justify-content: center;

                .teks-berita{
                    margin: 0;
                    font-size: 25px;
                    width: 50%; 
                    @media screen and (max-width: 800px) {
                        font-size: 20px;
                        width: 100%;
                    }
                }
            }
        }
    }

    .nama-anime-musim-ini-apl-animemo{
        display: flex;
        margin: 30px 0 10px 0;
        font-size: 13px;
        width: 100%;
        justify-content: center;
        color: var( --warna-tema-primary)
    }

    .kontainer-list-forum-apl-animemo{
        width: calc(100% - 20px);

    }

    .kontainer-anime-apl-animemo{
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        width: 100%;
        overflow-y: auto;
        height: 175px;
        justify-content: center;
        @media screen and (max-width: 1250px) and (min-width: 1000px){
            justify-content: left;
        }

        @media screen and (max-width: 750px) {
            justify-content: initial;
            overflow-y: auto;
        }

        @media screen and (min-width: 1000px){
            height: 275px;
        }

        @media (pointer:none), (pointer:coarse) {
            &::-webkit-scrollbar{
                display: none;
            }
        }


        .kontainer-isi-anime{
            border-radius: 10px;
            margin-right: 5px;
            width: 100px;
            height: 150px;
            position: relative;
            @media screen and (min-width: 1000px){
                height: 225px;
                width: 150px;
            }

            .gbr{
                top: 0;
                left: 0;
                width: 100px;
                height: 150px;
                object-fit: cover;
                @media screen and (min-width: 1000px){
                    height: 225px;
                    width: 150px;
                }
            }

            .kontainer-teks{
                background-image: linear-gradient(to top, var(--bg-gelap), var(--bg-transparan-full));
                position: absolute;
                bottom: 0;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                width: 100%;
                left: 0;
                height: 50px;

                .teks{
                    margin: 0;
                    font-size: 13px;
                    line-height: 14px;
                    height: 28px;
                    color: var(--warna-teks);
                    width: 100%;
                    text-align: center;
                    overflow: hidden;
                    @media screen and (min-width: 1000px){
                        font-size: 16px;
                        line-height: 16px;
                        height: 32px;
                    }
                }
            }
        }
    }

    .tbl-lihat-aktivitas{
        background-color: var(--bg-terang);
        border: none;
        border-radius: 6px;
        padding: 15px 0;
        margin: 10px 0 25px 0;
        width: 80%;
        max-width: 500px;
        color: var(--warna-teks);
        &:hover{
            cursor: pointer;
            background-color: var(--bg-aksen);
        }
    }
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
    margin-top: 20px
    
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}