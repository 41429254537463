@import '../../../../../css/Akar.scss';

.isi-forum-apl-animemo{
    padding: 10px;
    display: flex;
    flex-direction: row;
    width: calc(100% - 20px);
    height: 120px;
    border-radius: 5px;
    overflow: hidden;
    $lebar-gbr-forum: 100px;
    $lebar-gbr-forum-kecil: 75px;
    margin-bottom: 10px;
    background-color: $bg;
    &:hover{
        cursor: pointer;
        background-color: $bg-aksen;
    }
    @media screen and (max-width: $pindah-ui-3) {
        height: 80px;
    }

    .gbr-forum{
        width: $lebar-gbr-forum;
        height: 100%;
        object-fit: cover;
        margin-right: 15px;
        @media screen and (max-width: $pindah-ui-3) {
            width: $lebar-gbr-forum-kecil;
        }
    }

    .kontainer-isi-forum{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        @media screen and (max-width: $pindah-ui-3) {
            width: 100%;
        }

        .isi-forum{
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .teks-forum{
                width: 100%;
                display: flex;
                flex-direction: column;

                .kontainer-judul-forum{
                    display: flex;
                    flex-direction: row;
                    height: 25px;
                    margin-bottom: 10px;
                    justify-content: space-between;

                    .judul-forum{
                        margin: 0;
                        font-size: 22px;
                        color: $warna-teks;
                        font-weight: 600;
                        line-height: 24px;
                        height: 24px;
                        text-align: left;
                        width: calc(100% - 30px);
                        overflow: hidden;
                        @media screen and (max-width: $pindah-ui-3) {
                            font-size: 12px;
                            line-height: 13px;
                            height: 13px;
                        }
                        
                    }

                    .ikon-bendera{
                        width: fit-content;
                        height: fit-content;
                        padding: 2px;
                        background-color: var(--bg-tranparan-full);
                        border: none;
                        transition: background-color 200ms;

                        &:hover{
                            cursor: pointer;
                            background-color: var(--bg-terang);
                        }
                        svg{
                            color: var(--warna-teks)
                        }
                    }
                }

                .isi-forum{
                    margin: 0;
                    font-size: 15px;
                    text-align: left;
                    color: $teks-tak-menyala;
                    font-weight: 400;
                    line-height: 16px;
                    height: 48px;
                    width: 100%;
                    overflow: hidden;
                    @media screen and (max-width: $pindah-ui-3) {
                        font-size: 12px;
                        line-height: 13px;
                        height: 26px;
                    }
                }
            }

            .tgl-forum{
                margin: 0;
                font-size: 13px;
                font-weight: 400;
                color: $warna-teks;
                line-height: 12px;
                height: 12px;
                text-align: left;
            }
            
        }

        .statistik-bawah{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: fit-content;

            h6{
                margin: 0;
            }

            .waktu{
                font-size: 12px;
                font-weight: 500;
                color: var(--warna-teks);
                line-height: 12px;
                height: 12px;
                overflow: hidden;
                @media screen and (max-width: $pindah-ui-3) {
                    font-size: 10px;
                    line-height: 11px;
                    height: 11px;
                }
            }

            .uploader{
                font-size: 12px;
                font-weight: 500;
                color: var(--warna-teks);
                line-height: 12px;
                height: 12px;
                overflow: hidden;
                @media screen and (max-width: $pindah-ui-3) {
                    font-size: 10px;
                    line-height: 11px;
                    height: 11px;
                }
            }
        }
    }
}