@import "../../../../../css/Akar.scss";

.list-anime-musiman{
    display: flex;
    flex-direction: column;
    background-color: var(--bg);
    width: 100%;
    border-radius: 15px;
    align-items: center;
    transition: 200ms;
    overflow: hidden;
    &:hover{
        cursor: pointer;
        filter: brightness(1.2);
        background-color: $bg-terang;
    }
    @media screen and (max-width: $pindah-ui-2){
        border-radius: 5px;
    }

    @media screen and (min-width: 1200px) {
        width: 100%;
    }
    .kontainer-gbr{
        position: relative;
        margin-top: 20px;
        width: calc(100% - 40px);
        padding-bottom: 120%;

        @media screen and (max-width: $pindah-ui-2){
            width: 100%;
            margin-top: 0;
        }

        .gbr{
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 0;
            object-fit: cover;
        }

        .statistik-cepat{
            display: flex;
            flex-direction: row;
            position: absolute;
            background-color: var(--bg-transparan-80);
            color: var(--warna-tema-primary-terang);
            bottom: 5px;
            left: 5px;
            width: calc(100% - 20px);
            padding: 4px;
            align-items: center;
            height: fit-content;
            
            .gbr{
                position:static;
                font-size: 10px;
                margin: 0;
                width: fit-content;
                height: fit-content;
                svg{
                    font-size: 16px;
                    @media screen and (max-width: $pindah-ui-3) {
                        font-size: 10px;
                    }
                }
            }

            .isi{
                position:static;
                margin: 0;
                margin-left: 8px;
                font-size: 14px;
                margin-left: 10px;
                margin-right: 20px;
                font-size: 14px;
                @media screen and (max-width: $pindah-ui-3) {
                    font-size: 10px;
                }
            }
        }
    }

    .judul-komponen-musiman{
        margin: 5px 0 0 0;
        width: calc(100% - 40px);
        font-size: 20px;
        line-height: 25px;
        height: 50px;
        overflow: hidden;
        text-align: left;
        @media screen and (max-width: $pindah-ui-3) {
            font-size: 12px;
            font-weight: 600;
        }

    }
    .genre-komponen-musiman{
        margin: 5px 0 10px 0;
        font-size: 12px;
        width: calc(100% - 40px);
        text-align: left;
        line-height: 15px;
        height: 30px;
        overflow: hidden;

        @media screen and (max-width: $pindah-ui-3) {
            font-size: 10px;
        }
    }
}

.statistik-cepat-komponen-musiman{
    /* color: ; */
    display: flex;
    flex-direction: row;
    align-items: center;
    
}