:root {
    --bg: #000000;
    --bg-lebih-gelap: #0a0a0a;
    --bg-gelap: #121212;
    --bg-terang: #171717; 
    --bg-terang-terang: #1b1b1b; 
    --bg-aksen: #2c2c2c;
    --bg-abu-abu: #484a4d;
    --bg-transparan-80: #000000CC;
    --bg-transparan-70: #000000B3;
    --bg-transparan-60: #00000099;
    --bg-transparan-full: #00000000;
    --teks-abu-abu:#A8A8A8;
    --warna-teks: #ffffff;
    --teks-tak-menyala-terang: rgba(255, 255, 255, 0.7);
    --teks-tak-menyala:rgba(255, 255, 255, 0.5);

    --warna-tema-primary-terang: #ff7961;
    --warna-tema-primary-gelap: #ba000d;
    --warna-tema-primary: #f44336;

    --warna-teks-eror: #f44336;
    --ukuran-nav: 60px;
    --ukuran-nav-kanan: 20vw;
    --ukuran-nav-kanan-maks: 200px;
    --ukuran-nav-bawah: 45px;
    --border: 1px solid #ad6e6e;
    --batas: 1px solid #ad6e6e;
    --radius-border: 8px;
    --kecepatan:5000ms;
    --ukuran-item-tombol: calc(var(--ukuran-nav)*0.8);
    --ukuran-tombol: calc(var(--ukuran-nav)*0.5);
    --ukuran-font-navigasi: calc(var(--ukuran-nav)*0.5);


    --pindah-ui-6: 1200px;
    --pindah-ui-5: 1000px;
    --pindah-ui-4: 800px;
    --pindah-ui-3: 600px;
    --pindah-ui-2: 400px;
    --pindah-ui-1: 200px;
}

$bg: var(--bg);
$bg-lebih-gelap: var(--bg-lebih-gelap);
$bg-gelap: var(--bg-gelap);
$bg-terang: var(--bg-terang); 
$bg-aksen: var(--bg-aksen);
$bg-abu-abu: var(--bg-abu-abu);
$bg-transparan-80: var(--bg-transparan-80);
$bg-transparan-70: var(--bg-transparan-70);
$bg-transparan-60: var(--bg-transparan-60);
$teks-abu-abu: var(--teks-abu-abu);
$warna-teks: var(--warna-teks);
$teks-tak-menyala-terang: var(--teks-tak-menyala);
$teks-tak-menyala:var(--teks-tak-menyala);

$warna-tema-primary-terang: var(--warna-tema-primary-terang);
$warna-tema-primary-gelap: var(--warna-tema-primary-gelap);
$warna-tema-primary: var(--warna-tema-primary);

$warna-teks-eror: var(--warna-teks-eror);
$ukuran-nav: var(--ukuran-nav);
$ukuran-nav-kanan: var(--ukuran-nav-kanan);
$ukuran-nav-kanan-maks: var(--ukuran-nav-kanan-maks);
$ukuran-nav-bawah: var(--ukuran-nav-bawah);
$border: var(--border);
$batas: var(--batas);
$radius-border: var(--radius-border);
$kecepatan: var(--kecepatan);
$ukuran-item-tombol: var(--ukuran-item-tombol);
$ukuran-tombol: var(--ukuran-tombol);
$ukuran-font-navigasi: var(--ukuran-font-navigasi);


$pindah-ui-6: 1200px;
$pindah-ui-5: 1000px;
$pindah-ui-4: 800px;
$pindah-ui-3: 600px;
$pindah-ui-2: 400px;
$pindah-ui-1: 200px;

body { margin:0; color: var(--warna-teks);}