@import '../../../../../css/Akar.scss';


.kontainer-balas-anak-forum-apl-animemo{
    width: calc(100%);
    align-items: center;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0px;
    background-color: $bg;
    border-radius: 7px;

    @media screen and (max-width: $pindah-ui-5){
        bottom: calc(#{$ukuran-nav-bawah} + 10px)
    }

    
    .kontainer-gbr-tbl{
        background-color: $bg;
        border: none;
        border-radius: 10px;
        outline: none;
        transition: background-color 200ms;
        &:hover{
            cursor: pointer;
            background-color: $bg-aksen;
        }
        svg{
            font-size: 35px;
            color: $warna-teks;
            

            @media screen and (max-width: $pindah-ui-2) {
                font-size: 25px;
                
            }
        }
    }

    .kontainer-input-membalas{
        align-items: center;
        display: flex;
        min-width: 25px;
        width: fit-content;

        
        .input-membalas{
            margin: 0;
            padding: 5px;
            color: $teks-abu-abu;
            font-size: 15px;
        }
    }


    .kontainer-input-gbr{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        max-width: 500px;

    }

    .kontainer-teks-tbl{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .kontainer-balas-forum{
            &:hover{
                cursor: pointer;
                background-color: $bg-aksen;
            }

        }

    }
    .kontainer-teks{
        width: calc(100% - 90px);
        @media screen and (max-width: $pindah-ui-2) {
            width: calc(100% - 55px);
        }
    }
}
