@import "../css/Akar.scss";

.App {
  text-align: center;
}

.web-utama-animemo {
  background-color: var(--bg);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  font-size: calc(10px + 2vmin);
  color: var(--warna-teks);

  .konten-website-animemo{
    margin-top: calc(20px + var(--ukuran-nav));
    margin-left: 50px;
    margin-bottom: 40px;
    width: calc((100% - 100px) - var(--ukuran-nav-kanan-maks));
    background-color: var(--bg-gelap);
    border-radius: 20px;
    // overflow: hidden;

    @media screen and (max-width: $pindah-ui-5){
      width: calc(100% - 100px);
      margin-bottom: $ukuran-nav-bawah;
      
    }
    @media screen and (max-width: $pindah-ui-3){
      width: calc(100%);
      margin-top: $ukuran-nav;
      margin-left: 0;
      border-radius: 0;
    }
  }

}

.App-link {
  color: #f44336;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a { 
  color: var(--warna-teks);
}
