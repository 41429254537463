@import "../../css/Akar.scss";


.konten-profil{
    width: calc(100%);

    .header-profil{
        background-color: var(--bg-terang);
        display: flex;
        justify-content: center;
        width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
    }
    
    .nama-pengguna-profil{
        width: 100%;
        display: flex;
        justify-content: left;
    }
    
    .kontainer-gambar-profil{
        --ukuran-foto-profil: 200px;
        display: flex;
        justify-content: center;
        margin-right: 20px;
        margin-left: 20px;
        align-items: center;
        height: var(--ukuran-foto-profil);
        width: var(--ukuran-foto-profil);
    }
    
    .gambar-profil{
        border-radius: 50%;
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
    }
    
    .gambar-profil-vertikal{
        --ukuran-foto-profil: 30vw;
        border-radius: 50%;
        display: flex;
        margin-right: 20px;
        margin-left: 20px;
        height: var(--ukuran-foto-profil);
        width: var(--ukuran-foto-profil);
        justify-content: center;
    }
    
    .isi-profil{
        display: flex;
        flex-direction: column;
        min-width: 30%;
    }
    
    .tentang-pengguna-profil{
        margin: 0;
        display: flex;
        justify-content: center;
        margin-top: 20px;
        width: 100%;
        font-size: medium;
    
    }
    
    .info-cepat-profil{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 50px;
    }
    
    .kontainer-tombol-logout{
        margin-top: 25px;
        width: 100%;
        flex-direction:column;
        align-items: center;
    
    }
    
    .stat-cepat{
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        justify-content: space-evenly;
        width: 100%;
    }
    
    .prompt-download-animemo-profil{
        margin-top: 60px;
    }
    
    @media only screen and (orientation: portrait){
        .header-profil{
            background-color: var(--bg-lebih-gelap);
        }
        .kontainer-gambar-profil{
            margin-left: 0;
        }
    }

    .tbl-lihat-aktivitas{
        background-color: var(--bg-terang);
        border: none;
        border-radius: 6px;
        padding: 15px 0;
        margin: 10px 0 25px 0;
        width: 80%;
        max-width: 500px;
        color: var(--warna-teks);
        &:hover{
            cursor: pointer;
            background-color: var(--bg-aksen);
        }
    }
    
}

