@import '../../../css/Akar.scss';

.kontainer-teks-input-cek-boks{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-bottom: var(--teks-tak-menyala);
    min-height: 48px;
    border-radius: 5px;
}

.teks-input-cek-boks{
    color: var(--teks-tak-menyala);
    font-size: large;
    flex-wrap: wrap;
    padding-right: 5px;
    transition: background-color 200ms;
}

.teks-input-cek-boks-terisi{
    font-size: large;
    text-align: left;
    color: var(--warna-teks);
    padding-right: 5px;
    transition: background-color 200ms;
}

.teks-input-cek-boks:hover{
    background-color: var(--bg-aksen);
    cursor:pointer
}

.teks-input-cek-boks-terisi:hover{
    background-color: var(--bg-aksen);
    cursor:pointer
}