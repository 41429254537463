@import '../../../css/Akar.scss';

.isi-forum-apl-animemo{
    padding: 10px;
    display: flex;
    flex-direction: row;
    width: calc(100% - 20px);
    height: 120px;
    border-radius: 5px;
    overflow: hidden;
    $lebar-gbr-forum: 100px;
    $lebar-gbr-forum-kecil: 75px;
    margin-bottom: 10px;
    background-color: $bg;
    &:hover{
        cursor: pointer;
        background-color: $bg-aksen;
    }
    @media screen and (max-width: $pindah-ui-3) {
        height: 80px;
    }

    .gbr-forum{
        width: $lebar-gbr-forum;
        height: 100%;
        object-fit: cover;
        margin-right: 15px;
        @media screen and (max-width: $pindah-ui-3) {
            width: $lebar-gbr-forum-kecil;
        }
    }

    .isi-forum{
        width: calc(100% - #{$lebar-gbr-forum});
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media screen and (max-width: $pindah-ui-3) {
            width: calc(100% - #{$lebar-gbr-forum-kecil});
        }

        .teks-forum{
            width: 100%;
            display: flex;
            flex-direction: column;

            .judul-forum{
                margin: 0;
                font-size: 22px;
                color: $warna-teks;
                font-weight: 600;
                line-height: 22px;
                height: 22px;
                margin-bottom: 10px;
                text-align: left;
                width: 100%;
                overflow: hidden;
                @media screen and (max-width: $pindah-ui-3) {
                    font-size: 14px;
                    line-height: 14px;
                    height: 14px;
                }
            }

            .isi-forum{
                margin: 0;
                font-size: 15px;
                text-align: left;
                color: $teks-tak-menyala;
                font-weight: 400;
                line-height: 16px;
                height: 48px;
                width: 100%;
                overflow: hidden;
                @media screen and (max-width: $pindah-ui-3) {
                    font-size: 12px;
                    line-height: 13px;
                    height: 39px;
                }
            }
        }

        .tgl-forum{
            margin: 0;
            font-size: 13px;
            font-weight: 400;
            color: $warna-teks;
            line-height: 12px;
            height: 12px;
            text-align: left;
        }
        
    }
}