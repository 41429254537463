

.navigasi-bawah-website{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    position: fixed;
    right: 0;
    bottom: calc(0px + env(safe-area-inset-bottom));
    height: var(--ukuran-nav-bawah);
    width: 100%;
    z-index: +1;
    background-color: var(--bg);
    padding-left: 0;

    .isi-navigasi-bawah-website{
        width: 100%;
        height: 100%;
        background-position: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: var(--warna-teks);
        background-color: var(--bg);
        transition: background-color 200ms;
        &:hover{
            cursor: pointer;
            background-color: var(--bg-abu-abu);
        }

        @media (pointer:none), (pointer:coarse) {
            &:hover{
                background-color: var(--bg);
            }
            &:active{
                cursor: pointer;
                background-color: var(--bg-abu-abu);
            }
        }


        h6{
            margin: 0;
            font-size: 12px;
            font-weight: 600;
        }
        svg{
            font-size: 20px;

        }
    }
}