
.kontainer-seluruh-komentar-apl-animemo{

    .list-komentar{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 200px;
        padding: 0;
        margin-top: 20px;

        .isi-list-komentar{
            width: calc(100% - 60px);
            padding: 10px;
            margin-bottom: 8px;
            background-color: var(--bg);
            display: flex;
            flex-direction: row;
            align-items: center;

            p{
                font-size: 15px;
                font-weight: 500;
                margin: 0;
                width: calc(100% - 26px);
                color: var(--warna-teks);
                text-align: left;
            }

            .ikon-hapus{
                width: 20px;
                height: 20px;
                object-fit: contain;
                &:hover{
                    cursor: pointer;
                }
            }
        }
    }
    
    .kontainer-teks-komentar-feeds{
        width: calc(100% - 40px);
        align-items: center;
        height: fit-content;
        display: flex;
        flex-direction: row;
        margin: 0 0 20px 20px;
        position: -webkit-sticky;
        position: sticky;
        bottom: 0px;
        background-color: var(--bg);
        border-radius: 7px;
    
        @media screen and (max-width: 1000px){
            bottom: calc(#{var(--ukuran-nav-bawah)} + 10px)
        }
    
        .kontainer-teks{
            width: calc(100% - 35px);
            @media screen and (max-width: 400px) {
                width: calc(100% - 35px);
            }
        }
    
        .kontainer-gbr-tbl{
            background-color: var(--bg);
            border: none;
            border-radius: 10px;
            outline: none;
            transition: background-color 200ms;
            &:hover{
                cursor: pointer;
                background-color: var(--bg-aksen);
            }
            svg{
                font-size: 35px;
                color: var(--warna-teks);
                
    
                @media screen and (max-width: 400px) {
                    font-size: 25px;
                }
            }
        }
    
    }
}

