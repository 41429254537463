@import "../../css/Akar.scss";

.navbar{
    height: var(--ukuran-nav);
    width: 100%;
    position: fixed;
    background-color: var(--bg);
    border-bottom: var(--border);
    z-index: +2;
}

.navbar-nav {
    width: 100%;
    height: 100%;
    justify-content: space-between;
    display: flex;
}

.nav-logo{
    width: calc(var(--ukuran-nav)*0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: flex-start;
}

.nav-seluruh-logo{
    color: var(--warna-teks);
    display: flex;
    flex-direction: row;
    font-size: calc(var(--ukuran-nav)*0.5);
    border-radius: 5px;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: auto;
    justify-content: flex-end;
    transition: background-color 300ms;
}

.nav-teks-logo{
    margin-left: 10px;
}

.nav-seluruh-logo:hover{
    background-color: var(--bg-aksen);
    cursor:pointer;
}

.item-nav{
    display: flex;
    margin-left: auto;
    margin-right: 10px;
    align-items: center;
    justify-content: flex-end;
}

.tombol-ikon{
    --ukuran-tombol: calc(var(--ukuran-nav)*0.5);
    width: var(--ukuran-tombol);
    height: var(--ukuran-tombol);
    background-color: var(--bg-aksen);
    border-radius: 50%;
    padding: 5px;
    margin: 2px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
    h6{
        font-size: 15px;
        margin: 0;
        color: var(--warna-teks);
    }
}

.gambar-item-nav-atas{
    display: flex;
    color: var(--warna-teks);
    --ukuran-tombol: calc(var(--ukuran-nav)*0.5);
    width: var(--ukuran-tombol);
    height: var(--ukuran-tombol);
    border-radius: 50%;
    margin: 2px;
    align-items: center;
    justify-content: center;
}

.tombol-ikon:hover{
    filter: brightness(1.4);
    cursor: pointer;
}

.tombol-ikon svg {
    fill: var(--warna-teks);
    width: 20px;
    height: 20px;
}