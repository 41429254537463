@import '../../css/Akar.scss';

.konten-login-pengguna{
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;
    padding-bottom: 50px;

    .peringatan-login{
        font-size: 15px;
        font-weight: 500;
    }

    .kontainer-kontainer-input-gbr{
        display: flex;
        width: 80%;
        justify-content: center;
        .kontainer-input-gambar{
            width: 100%;
            max-width: 700px;
            height: fit-content; 
        }
    }
}

.kontainer-tombol-buat-akun-login{
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.tombol-buat-akun-login{
    margin-top: 50px;
    width: fit-content
}

.buat-akun-login{
    width: 100%;
}