@import '../../../css/Akar.scss';

.input-gambar{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .gbr-place-holder{
        width: 100%;
        height: 100%;
    }

    .input-input-gambar{
        display:none;

    }
    
    .kontainer-gambar-input-gambar{
        width: 100%;
        max-width: 60vh;
        height: 0px;
        transition: background-color 300ms;
        &-bundar{
            border-radius: 50%;
            overflow: hidden;
        }
    }
    
    .kontainer-gambar-input-gambar:hover{
        cursor: pointer;
        background-color: var(--bg-aksen);
    }

    .kontainer-gambar-input-gambar-bundar:hover{
        cursor: pointer;
        background-color: var(--bg-aksen);
    }

    .placeholder-input-gambar{
        width: 100%;
        height: 100%;
        font-size: 100%;
    }
    
}










.modal-input-gambar-login{
    display: flex;
    align-items: center;

    justify-content: center;

    .kontainer-modal-crop-input-gambar{
        display:flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        height: 80%;
        border-radius: 20px;
        background-color: var(--bg-aksen);
    }

    .kontainer-crop-input-gambar{
        width: 100%;
        height: 80%;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .crop-input-gambar{
        width: fit-content;
        height: fit-content;
        max-width: 100%;
        max-height: 100%;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            max-height: 100%;
            max-width: calc(100vh/2);
        }
    }

    .kontainer-tombol-crop-input-gambar{
        margin-top: 50px
    }
    .petunjuk-crop-input-gambar{
        width: calc(100% - 5px);
        text-align: center;
        font-size:medium;
        padding: 5px;
        color: var(--warna-teks);
    }

}



@media screen and (min-width: 600px){
    .kontainer-gambar-input-gambar{
        width: 350px;
        height: 0px;
    }
    .kontainer-gambar-input-gambar-bundar{
        width: 350px;
        height: 0px;
    }
}