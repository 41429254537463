@import '../../../../css/Akar.scss';

.item-stat-cepat{
    display: flex;
    flex-direction: column;
    min-width: 10%;
}

.angka-stat-cepat{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: larger;
}

.keterangan-stat-cepat{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: medium;
}