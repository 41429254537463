.pemberitahuan-apl-animemo{
    display: flex;
    flex-direction: column;
    align-items: center;

    .list-pemberitahuan{
        list-style-type: none;
        padding-left: 0;
        display: flex;
        width: 100%;
        flex-direction: column;
    }
}