@import '../../../../../css/Akar.scss';

.isi-ortu-forum-apl-animemo-list{
    width: calc(100% - 20px);
    height: fit-content;
    margin-bottom: 10px;

}

.isi-ortu-forum-apl-animemo{
    margin: 0;
    height: 100px;
    display: flex;
    flex-direction: row;
    border-radius: 5px; 
    padding: 10px;
    width: calc(100% - 20px);
    overflow: hidden;
    background-color: $bg;
    transition: background-color 200ms;
    &:hover{
        cursor: pointer;
        background-color: $bg-aksen;
    }

    .gbr-ortu-forum{
        width: 100px;
        height: 100%;
        object-fit: cover;
        margin-right: 15px;
    }

    .teks-ortu-forum{
        display: flex;
        flex-direction: column;
        width: calc(100% - 100px);

        .judul{
            margin: 0;
            font-size: 18px;
            color: $warna-teks;
            font-weight: 700;
            line-height: 20px;
            max-height: 40px;
            text-align: left;
            margin-bottom: 10px;
        }

        .isi{
            margin: 0;
            font-size: 15px;
            color: $teks-tak-menyala-terang;
            font-weight: 500;
            line-height: 15px;
            max-height: 45px;
            text-align: left;
        }

    }


}