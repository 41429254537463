

.konten-daftar-pengguna{
    border-radius: 20px;
    overflow: hidden;
    .tab-tab-daftar{
        width: 100%;
        display: flex;
        flex-direction: column;
        
    }
}