@import '../../../../css/Akar.scss';

.kontainer-skor-anime{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 120px
}

.teks-skor-anime{
    font-size: small;
    padding: 5px;
    border-radius: 5px;
    background-color: var(--warna-tema-primary-gelap);
}

.skor-anime{
    font-size: larger;
}