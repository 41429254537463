@import "../../css/Akar.scss";

.konten-anime-utama-apl-animemo{
    display: flex;
    flex-direction: column;
    align-items: center;

    .fab-tambah-anime{
        position: fixed;
        right: calc(20px + #{$ukuran-nav-kanan});
        bottom: 20px;
        @media only screen and (max-width: $pindah-ui-5) {
            right: 20px;
            bottom: calc(env(safe-area-inset-bottom) + #{$ukuran-nav-bawah} + 20px)
        }
    }

    .gambar-anime {
        max-height: 70vmin;
        width: 100%;
        pointer-events: none;
        object-fit: contain;
    }
    
    .genre-anime{
        margin-top: 20px;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
    
    .kontainer-seluruh-skor{
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        width: 100%;
        justify-content: center;
    }
    
    .teks-header-kecil{
        margin-top: 20px;
        width: 70%;
        font-size: medium;
    }
    
    .kontainer-teks-sinopsis{
        margin-top: 20px;
        width: 70%;
        font-size: medium;
        color: var(--teks-abu-abu);
        white-space: pre-line;
        text-align: start;
    }
    
    .isi-tabel-anime{
        width: 90%;
        margin-top: 20px;
        font-size: small;
        border-spacing: 5px;
        color: var(--teks-abu-abu)
    }
    
    .bagian-kiri-tabel-anime{
        vertical-align: top;
        text-align: right;
        width: 50%;
    }
    
    .bagian-kanan-tabel-anime{
        vertical-align: top;
        text-align: left;
        white-space: pre-line;
        width: 50%;
    }
    
    @media only screen and (orientation:portrait){
        .jenis-statistik-anime{
            width: 90%;
        }
    
        .kontainer-teks-sinopsis{
            width: 90%
        }
    
        .bagian-kiri-tabel-anime{
            width: 30%;
        }
    
        .bagian-kanan-tabel-anime{
            width: 70%;
        }
    }
}

