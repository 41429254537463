@import '../../../../../css/Akar.scss';


.isi-forum-anak-apl-animemo{
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: $bg;
    border-radius: 5px;
    margin-bottom: 10px;

    .header-anak-forum{
        display: flex;
        flex-direction: row;
        width: calc(100%);
        margin-bottom: 10px;


        .kontainer-gbr{
            &:hover{
                cursor: pointer;
            }
            .foto-profil-anak-forum{
                width: 35px;
                height: 35px;
                border-radius: 17.5px;
                margin-right: 5px;
                object-fit: cover;

            }
        }

        .teks-header{
            display: flex;
            flex-direction: column;
            width: calc(100% - 60px);
            height: 30px;
            margin-right: 5px;

            .nama-pengguna{
                margin: 0;
                font-size: 15px;
                font-weight: 500;
                color: $teks-tak-menyala-terang;
                line-height: 17px;
                width: 100%;
                text-align: left;
                margin-bottom: 5px;
            }

            .tgl-pos{
                margin: 0;
                font-size: 13px;
                font-weight: 500;
                color: $teks-tak-menyala-terang;
                line-height: 14px;
                width: 100%;
                text-align: left;
            }
        }

        .ikon-bendera{
            width: fit-content;
            height: fit-content;
            padding: 2px;
            background-color: var(--bg);
            border: none;
            transition: background-color 200ms;
            &:hover{
                cursor: pointer;
                background-color: var(--bg-aksen);
            }
            svg{
                color: var(--warna-teks)
            }
        }
    }

    .balasan{
        margin: 5px 0 10px 0;
        font-size: 12px;
        border-radius: 5px;
        padding: 10px;
        color: var(--teks-tak-menyala-terang);
        border: 2px solid var(--teks-tak-menyala);
    }

    .gbr-pos{
        width: 100%;
        max-height: 225px;
        object-fit: contain;
        margin-bottom: 20px;
    }

    .isi-teks{
        margin: 0;
        font-size: 17px;
        color: $warna-teks;
        font-weight: 500;
        width: 100%;
        text-align: left;
        border-radius: 10px;
        white-space: pre-line;
        &:hover{
            background-color: $bg-aksen;
            cursor: pointer;
        }
    }
}