@import '../../../css/Akar.scss';

.input-umum-login{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    width: 100%;
}

.isian-input-umum-login{
    display: flex;
    align-items: flex-start;
    width: 60%;
    
}

.gambar-input-umum-login{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: small;
    margin-right: 10px;
    max-width: 15%;
}

.eror-input-umum-login{
    display: flex;
    align-items: center;
    margin-left: 10px;
    color: var(--warna-teks-eror);
    font-size: small;
    max-width: 15%;
}

@media only screen and (orientation:portrait){
    .eror-input-umum-login{
        margin-left: 5%
    }
    .gambar-input-umum-login{
        margin-right: 5%
    }
}