@import "../../css/Akar.scss";

/* input dari pengguna */

.inputCari{
  display: flex;
  align-items: center;
  border: 0;
}

.cari input{ 
    --tinggi-cari: 30px;
    background-color: var(--bg);
    color: var(--warna-teks);
    border: 0;
    border-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    place-items: center;
    font-size: 18px;
    padding: 15px;
    height: 30px;
    width: 100%;
}

.cari input:focus{
    outline: none;
    display:grid;
    place-items: center;
}

/* Logo kaca pembesar */

.ikonCari{
    height: 60px;
    width: 50px;
    color: var(--warna-teks);
    background-color: var(--bg);
    border-radius: 2px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    display:grid;
    place-items: center;
}

.ikonCari svg {
  font-size: 30px;
}

/* Loading */

.loadingCari{
    border-radius: 8px;
}

.teksLoadingCari{
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-50%)
}

/* hasil pencarian */

.hasilIndeks {
    margin-top: 5px;
    width: 100%;
    height: 325px;
    background-color: var(--bg);
    align-items: center;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 8px;
}

.hasilIndeks::-webkit-scrollbar{
    display: none;
}

.hasilIndeks .itemIndeks{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    display: flex;
    color: var(--warna-teks);
    transition: height 200ms;
}

.itemIndeks:hover{
    background-color: #484a4d;
    cursor:pointer;
    height: 70px;
}

.itemIndeks p {
    margin-left: 10px;
}

#tblBersihkan {
    cursor: pointer;
}