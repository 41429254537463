

.tambahkan-forum-apl-animemo{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    h5{
        margin: 10px 0;
        font-size: 18px;
        color: var(--warna-teks)
    }
    
    .isian-forum{
        margin: 10px 0;
        width: 80%;
    }
    
    .kontainer-isian-foto{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: center;

        .isian-foto{
            margin: 10px 0;
            width: calc(100% - 25px);
            max-width: 600px;
            
        }

        .tbl-hapus-foto{
            background-color: var(--bg-transparan);
            border-radius: 5px;
            border: 0;
            height: fit-content;
            width: fit-content;
            align-items: center;
            justify-content: center;
            &:hover{
                background-color: var(--bg-aksen);
                cursor: pointer;
            }
            svg{
                color: var(--warna-teks)
            }
        }
    }

    .kontainer-tbl{
        margin: 20px 0;

    }
}