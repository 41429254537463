@import '../../../css/Akar.scss';


.forum-apl-animemo{
    width: calc(100% - 20px);
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 10px;

    .judul-forum{
        margin: 0;
        font-size: 27px;
        font-weight: 700;
        color: $warna-teks;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }

    .fab-tambahkan-forum{
        position: fixed;
        right: calc(20px + #{$ukuran-nav-kanan});
        bottom: 20px;
        @media only screen and (max-width: $pindah-ui-5) {
            right: 20px;
            bottom: calc(env(safe-area-inset-bottom) + #{$ukuran-nav-bawah} + 20px)
        }
    }

    .pilihan-urutan{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;

        @mixin urutan-ternary($terpilih){
            margin: 0;
            padding: 20px;
            border-radius: 5px;
            font-size: 13px;
            font-weight: 13px;
            padding: 5px 10px;
            margin-left: 5px;
            margin-bottom: 15px;
            &:hover{
                background-color: $bg-aksen;
                cursor: pointer;
            }
            @if $terpilih{
                color: $warna-tema-primary
            }@else{
                color: $warna-teks
            }
        }
        .urutan{
            @include urutan-ternary(false)
        }
        .urutan-terpilih{
            @include urutan-ternary(true)
        }
    }

    .list-isi-forum{
        display: flex;
        flex-direction: column;
        padding-left: 0;
        list-style-type: none;
    }
}