@import '../../css/Akar.scss';

.laman-loading{
    display: flex;
    flex-direction: column;
    height: 250px;
}


.teks-loading{
    color: var(--warna-tema-primary);
}