@import "../../css/Akar.scss";

.navigasi-kanan{
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    top: var(--ukuran-nav);
    height: calc(100vh - var(--ukuran-nav) - env(safe-area-inset-bottom));
    width: var(--ukuran-nav-kanan);
    z-index: +1;
    background-color: var(--bg);
}

@media only screen and (min-width: $pindah-ui-5){
    .navigasi-kanan{
        width: var(--ukuran-nav-kanan-maks)
    }
}

.navigasi-kanan-atas{
    display: flex;
    flex-direction: column;
    padding: 5px;
    border-radius: 5px;
    width: calc(100% - 10px);
    margin-bottom: auto;
}

.navigasi-kanan-bawah{
    display: flex;
    flex-direction: column-reverse;
    padding: 5px;
    border-radius: 5px;
    width: calc(100% - 10px);
    margin-top: auto;
}

.isi-navigasi-kanan{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    transition: background-color 200ms;

    &:hover{
        cursor:pointer;
        background-color: var(--bg-aksen);
    }    

    .teks-isi-navigasi-kanan{
        margin-left: 5px;
        display: flex;
        height:min-content;
        color:var(--warna-teks);
    }
    
    .gambar-isi-navigasi-kanan{
        display: flex;
        color: var(--warna-teks);
        width: var(--ukuran-tombol);
        height: var(--ukuran-tombol);
        border-radius: 50%;
        padding: 5px;
        margin: 2px;
        align-items: center;
        justify-content: center;
    }    
}
