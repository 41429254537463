

.aktivitas-pengguna-apl-animemo{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .judul-aktivitas{
        font-size: 15px;
        font-weight: 500;   
    }

    .kontainer-item-aktivitas{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        list-style-type: none;
        padding-left: 0;
    }
}