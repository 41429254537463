@import '../../../css/Akar.scss';

.forum-anak-apl-animemo{
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);

    padding: 20px;

    .ikon-bendera-forum-anak-apl{
        width: fit-content;
        height: fit-content;
        padding: 2px;
        background-color: var(--bg);
        border: none;
        transition: background-color 200ms;
        &:hover{
            cursor: pointer;
            background-color: var(--bg-aksen);
        }
        svg{
            color: var(--warna-teks)
        }
    }
    
    .kontainer-urutan-anak-forum{
        width: calc(100%);
        align-items: center;
        height: 35px;
        display: flex;
        margin-bottom: 20px;
        position: -webkit-sticky;
        position: sticky;
        top: calc( #{$ukuran-nav} + 20px);
        background-color: $bg;
        border-radius: 7px;

        .gbr-silang-anak-forum{
            width: 25px;
            height: 25px;
            object-fit: contain;
            margin-left: 13px
        }

        .urutan-anak-forum{
            display: flex;
            flex-direction: row-reverse;
            width: calc(100% - 25px);
            margin-bottom: 20px;
            margin: 0;

            @mixin teks-ternary($terpilih){
                margin: 0;
                font-size: 13px;
                font-weight: 600;
                padding: 5px;
                margin-right: 5px;
                transition: background-color 200ms;
                &:hover{
                    background-color: $bg-aksen;
                    cursor: pointer;
                }
                @if $terpilih{
                    color: $warna-tema-primary
                }@else{
                    color: $warna-teks
                }
            }
    
            .teks{
                @include teks-ternary(false)
            }

            .teks-terpilih{
                @include teks-ternary(true)
            }
        }
    }

    .list-anak-forum{
        padding-left: 0;
        list-style-type: none;
        width: calc(100%);
        display: flex;
        flex-direction: column;

    }


}