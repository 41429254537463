

.ke-koleksi-pengguna-profil-apl-animemo{
    display: flex;
    flex-direction: row;
    height: 50px;
    width: 90%;
    margin-left: 5%;
    border: none;
    margin-top: 25px;
    margin-bottom: 25px;
    background-color: var(--bg-terang-terang);
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    &:hover{
        cursor: pointer;
        background-color: var(--bg-aksen);
    }

    h6{
        margin: 0;
        font-size: 20px;
        font-weight: 600;
        color: var(--warna-teks)
    }

    svg{
        color: var(--warna-teks);
        width: 50px;
        height: 50px;
    }
}